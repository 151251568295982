:root {
  --dark: #010101;
  --gray: #333;
  --light: rgb(242,245,251);
  --primary: #000099;
  --accent: #000099;
  --accent-light: #000099;
  --secondary: #34c1fd;
  --succes: #74c763;
}

body {
  background-color: var(--light);
}

input[type="radio"],
input[type="checkbox"] {
  accent-color: var(--accent);
}

#field-wrapper {
  border: 1px solid rgba(248, 249, 250, 0.5);
}
/** Border **/
.border-primary {
  border-color: var(--primary) !important;
}

.border-secondary {
  border-color: var(--accent) !important;
}

.border-dark {
  border-color: var(--dark) !important;
}

/** Background **/

.bg-light {
  background-color: var(--light);
  color: var(--primary);
}

.bg-black {
  background-color: #000;
}

.bg-gray {
  background-color: var(--gray);
}

.bg-dark {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-primary {
  background-color: var(--primary) !important;
}

.bg-accent {
  background-color: var(--accent);
}

/** Text **/

.text-dark {
  color: var(--dark) !important;
}

.text-accent {
  color: var(--accent);
}

.text-primary {
  color: var(--primary) !important;
}

.text-success {
  color: var(--succes) !important
}

/** Buttons */

.btn {
  border-radius: 25px !important;
}

.btn-primary:disabled {
  background-color: var(--primary);
  opacity: 0.7;
}

.btn-primary {
  background-color: var(--primary);
  border: 1px solid var(--primary);
}

.btn-primary:focus {
  background-color: var(--accent);
  box-shadow: none;
}

.btn-primary:hover {
  color: var(--primary);
  background-color: white;
  border: 1px solid var(--primary);
}

.btn-primary:active,
.btn:first-child:active {
  background-color: var(--primary);
}

.btn-clear:focus {
  background-color: transparent !important;
  border: 0px;
  box-shadow: none;
}

.btn-clear:hover {
  color: var(--primary);
  border: 0px;
  background-color: transparent !important;
}

.btn-clear:active,
.btn:first-child:active {
  background-color: transparent !important;
  color: var(--dark);
  border: 0px;
}

.btn-secondary {
  background-color: var(--accent);
  color: var(--dark);
  border: 0px;
}

.btn-outline-primary {
  border-color: var(--primary);
  color: var(--primary);
}

.btn-outline-primary:disabled {
  border-color: var(--primary);
  color: var(--primary);
}

.btn-outline-primary:hover {
  background-color: var(--accent);
  border: var(--accent);
}

.btn-light {
  background-color: var(--light);
}

/** Forms **/

.form-control {
  padding-left: 18px;
  border: 0.5px solid rgba(0, 0, 0, 0.2)
}

.input-group .form-control {
  padding-left: 12px;
}

.form-control::placeholder {
  font-size: 14px;
}

.form-control:focus,
.form-control:focus-within,
.form-control:active {
  box-shadow: none;
}

/** General **/

.menu-category:hover {
  background-color: var(--accent);
}

.menu-category:active {
  box-shadow: none;
  background-color: var(--accent);
}

.menu-category:focus {
  box-shadow: none;
  background-color: var(--accent);
}

.nav-item:hover {
  background-color: var(--accent);
  color: #000;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: var(--accent);
  color: #fff;
}

.user {
  background-color: var(--accent);
}

.nav-item .mobile:focus {
  background-color: var(--accent);
}

.image-input-container {
  border: 2px solid var(--accent);
}

.user-menu {
  background-color: var(--gray);
}

.hover-light:hover {
  color: var(--dark) !important;
  background-color: var(--light);
}

.status-badge {
  background-color: var(--accent-light);
}


/* Ionic */

.ion-color-accent {
  background-color: var(--accent-light) !important;
  padding: 4px 6px;
}
