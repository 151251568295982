.input-group {
    border: 0.5px solid rgba(0,0,0,0.1);
    border-radius: 25px !important;
    padding-left: 12px;
    padding-right: 12px;
}

.input-group i {
    font-size: 14px;
}

.input-group .form-control {
    border: 0px;
    font-size: 14px;
    border-radius: 25px !important;
}

.input-group-text {
    border: 0px;
    border-radius: 25px;
    background-color: white;
}

.form-control {
    padding-top: 10px !important;
    border-radius: 25px !important;
    padding-bottom: 10px !important;
}