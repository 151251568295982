@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

@font-face {
    font-family: "Cocogoose", sans-serif;
    src: url("../assets/fonts/Cocogoose.woff");
}

:root {
    --bs-font-sans-serif: "Poppins", sans-serif !important;
}

h1, h2, h3, .header {
    font-family: "Cocogoose", sans-serif !important;
}

h4, h5, h6, p, div, a, span, li {
    font-family: "Poppins", sans-serif !important;
    font-weight: 400;
}

h3,
h4,
h5,
h6,
.h3,
.h4,
.h5,
.h6 {
    line-height: 1.4em;
}

h3,
h4,
h5,
h6,
.h3,
.h4,
.h5,
.h6 {
    font-weight: 400
}

p,
div,
a,
input,
body {
    font-size: 0.8rem;
}

h2 {
    font-size: 1rem;
}

h3 {
    font-size: 0.9rem;
}

h4 {
    font-weight: 600;
    font-size: 0.8rem;
}

span.h6 {
    font-size: 1rem;
}

p,
div,
a,
input,
body {
    font-weight: 400;
}

.display-2, .display-3, .display-4, .display-5 {
    font-weight: 600;
}

.font-header {
    font-family: 'Abril Fatface', sans-serif !important;}

