html {
  width: 100vw;
  max-width: 100vw;
}

.container {
  max-width: 500px;
}

#root {
  width: 100vw;
  overflow-x: hidden;
}

.overlay {
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  opacity: 0.7;
  height: 100%;
  position: absolute;
 
}

.badge {
  font-size: 12px;
}

.bg-image {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.hidden {
  visibility: hidden;
}

.app-link {
  cursor: pointer;
  position: relative;
}

.user-thumbnail {
  width: 40px;
  height: 40px;
  border-radius: 150px;
  object-fit: cover;
}

.home-row {
  height: 50vh;
}

.home-img {
  height: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.login-icon {
  max-width: 70px;
}

.transfer-deposit {
  max-height: 400px;
  object-fit: contain;
  object-position: left;
}

.alert {
  z-index: 1000;
}

.nav-container {
  overflow-y: scroll;
  max-height: 75vh;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.nav-container::-webkit-scrollbar {
  display: none;
}

.product-img {
  height: 500px;
  max-width: 100%;
  width: 100%;
  border-radius: 10px 10px 0px 0px;
  object-fit: cover;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.react-datetime-picker__wrapper {
  border: 0px !important;
}

.panel-mobile-menu {
  display: none;
}

.panel-header-logo {
  max-width: 65px;
}

.video-form-thumbnail {
  height: 200px;
  object-fit: cover;
}

.capitalize {
  text-transform: capitalize;
}

.card-type {
  max-width: 50px;
}

#fileInput {
  position: absolute;
  visibility: hidden;
}

.image-input-container {
  background-color: none;
  width: 200px;
  height: 50px;
  border-radius: 10px;
  margin-bottom: 12px;
}

.bmOFCG {
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.hidden {
  display: none;
}

.text-large {
  font-size: 1em;
}

.carousel-inner {
  border-radius: 10px;
}

.carousel-img {
  width: 100%;
  border-radius: 10px;
  max-height: 50vh;
  object-fit: cover;
}

.schedule-class {
  border-radius: 10px;
  overflow: hidden;
}

.class-container {
  height: 200px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.text-auto {
  text-transform: none;
}

.vigencia {
  font-size: 18px;
}

.schedule-class {
  cursor: pointer;
}

.schedule-class:hover {
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.15) !important;
}

.schedule-col {
  width: calc(100% / 7);
}

.schedule-container {
  width: 170%;
}

.br-10 {
  border-radius: 10px;
}

.video-panel {
  position: relative;
}

.video-panel-title {
  position: absolute;
  bottom: 16px;
  left: 16px;
}

.video-panel-overlay {
  background-color: #000;
  position: absolute;
  height: 50vh;
  opacity: 0.5;
  width: 100%;
  left: 0px;
  top: 0px;
}

.video-panel-img {
  height: 50vh;
  object-fit: cover;
}

.swipe-container {
  max-width: 100%;
  display: flex;
  overflow-x: scroll;
  transition: all 0.5s;
  scroll-behavior: smooth;
  margin-bottom: 16px;
}

.swipe-container::-webkit-scrollbar {
  display: none;
}

.day-card .card {
  min-height: 100px;
  background-color: transparent;
}

.card {
  border: 0px;
  padding: 24px;
  border-radius: 15px;
  transition: all 0.25s;
}

.payment-method {
  max-width: 75px;
}

.color-legend {
  height: 25px;
  width: 25px;
  border-radius: 5px;
}

.sale-ribbon {
  position: absolute;
  right: 0;
  top: 20px;
}

.head-coach-ribbon {
  position: absolute;
  font-weight: 800;
  color: #000;
  left: 0px;
  top: 20px;
  width: 30px;
}

.strike-through {
  text-decoration: line-through;
}

.transition {
  transition: all 1s;
}

.video-iframe {
  width: 100%;
  height: 75vh;
}

.slideshow-logo {
  max-width: 450px;
}

.slideshow-img {
  height: 100vh;
  object-fit: cover;
}

.slideshow-text {
  position: absolute;
  top: 25%;
  padding: 16px;
  z-index: 10;
  max-width: 600px;
}

.z-10 * {
  z-index: 10;
}

.hero-content {
  max-width: 600px;
}

.carousel-overlay {
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 1) 75%,
    rgba(255, 255, 255, 0) 150%
  );
  opacity: 0.5;
  position: absolute;
  display: block;
  height: 80vh;
  width: 100%;
  top: 0;
}

.menu-category {
  font-size: 1.2rem;
  width: 100%;
}

.collapse-nav-item {
  color: #fff;
  display: block;
  width: 100%;
  text-decoration: none !important;
  letter-spacing: 0.5px;
  font-size: 1.2rem;
  padding-top: 12px;
  padding-bottom: 12px;
}

.collapse-nav-item {
  padding-left: 32px;
}

.collapse-nav-item:hover {
  color: #ababab;
}

.collapsible {
  transition: all 0.1s;
  overflow: hidden;
}

.closed {
  height: 0px;
}

body {
  width: 100%;
  max-width: 100%;
}

.navbar {
  position: fixed;
  z-index: 200;
  width: 100%;
  top: 0px;
  left: 0;
}

#navbar {
  max-width: 100vw;
  overflow: hidden;
}

.nav-item {
  height: 60px;
  padding-left: 12px;
  padding-right: 12px;
  color: #fff;
  font-weight: 800;
}

.navbar-cta {
  margin-top: 10px;
  transition: all 0.25s;
}

.navbar-cta:hover {
  transform: scale(1.05);
}

.navbar-brand {
  padding-top: 0px;
}

.nav-link {
  display: inline-block;
  color: inherit;
}

.nav-link:hover {
  color: inherit;
}

.top-banner {
  left: 0;
  top: 0;
  z-index: 100;
  height: 30px;
  text-align: center;
  font-weight: 800;
  padding: 2px;
  width: 100%;
  overflow: hidden;
  cursor: pointer;
}

.logo-landing {
  max-width: 300px;
}

.text-funny {
  font-size: 4rem;
}

.text-left {
  text-align: left !important;
}

.side-menu {
  left: 0;
  width: 160px;
  color: #fff;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-top: 32px;
  padding-bottom: 64px;
}

.main-panel {
  padding-top: 32px;
  min-height: 100vh;
}

.dropdown-item {
  color: #fff;
}

.logo-footer {
  max-width: 250px;
}

.h-80 {
  height: 85vh;
  overflow-y: scroll;
}

.hover-overlay {
  background-color: rgba(0, 0, 0, 0.8);
  height: 100%;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 10px;
  cursor: pointer;
  transition: all 1s;
  padding: 16px;
}

.profile-image {
  max-width: 300px;
}

.video-background {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 90vh;
}

.thumbnail {
  max-width: 16px;
}

.profile-thumbnail {
  border-radius: 100px;
  max-width: 100%;
}

.video-thumbnail {
  height: 200px;
  object-fit: cover;
  width: 100%;
  max-width: 100%;
  border-radius: 10px 10px 0px 0px;
}

.card-title {
  font-size: 2em;
  font-weight: bold;
}

.card-video-title {
  font-weight: 800;
}

.card-video {
  border-radius: 10px;
  height: 350px;
  margin-bottom: 32px;
  padding-bottom: 16px;
  color: #fff;
}

.card-video:hover {
  transform: scale(1.1);
}

.card-video p {
  font-size: 14px;
}

.card-video h3 {
  font-size: 16px;
}

.banner-bg {
  padding-top: 150px;
  padding-left: 32px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 500px;
}

.panel-title {
  padding-bottom: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  margin-bottom: 32px;
}

.filter-card {
  border-radius: 10px;
  padding: 16px;
  margin-bottom: 24px;
}

.bold {
  font-weight: 600;
}

.cursor-pointer {
  cursor: pointer;
}

.text-right {
  text-align: right;
}

.latest-card {
  height: 60vh;
  margin-bottom: 32px;
  cursor: pointer;
  overflow: hidden;
  border-radius: 10px;
}

.latest-card-thumbnail {
  width: 100%;
  max-width: 100%;
  height: 100%;
  object-fit: cover;
}

.latest-card-overlay {
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 1) 70%,
    rgba(255, 255, 255, 0) 100%
  );
  opacity: 0.8;
  height: 100%;
  position: absolute;
  display: block;
  top: 0;
  width: 75%;
}

.latest-card-content {
  position: absolute;
  width: 40%;
  padding: 16px;
  top: 25%;
}
.latest-card-content h2 {
  font-size: 3em;
}

.user-name {
  font-size: 20px;
}

.user {
  border-radius: 200px;
  color: #fff;
  text-align: center;
  align-items: center;
  display: flex;
  width: 50px;
  height: 50px;
  margin-left: auto;
}

.menu-logo {
  max-width: 75px;
}

.side-nav {
  display: block;
  margin-top: 32px;
  list-style-type: none;
  padding-left: 0px;
}

.side-nav li {
  margin-bottom: 16px;
}

.logout-container {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  position: absolute;
  bottom: 16px;
  width: 75%;
}

.home-video {
  height: 90vh;
  object-fit: cover;
}

#video-row {
  margin-top: 60px !important;
}

.logo-navbar {
  max-width: 50px;
}

.trailer {
  max-width: 1100px;
  width: 100%;
}

.mvh-100 {
  min-height: 100vh;
}

.text-banner {
  animation: animate 60s linear infinite;
}

@keyframes animate {
  0% {
    transform: translateX(0%);
  }
}

#main-landing {
  overflow-x: hidden;
  max-width: 100vw;
}

.login-card {
  max-width: 500px !important;
  margin: auto;
  display: block;
}

.login-image {
  object-fit: cover;
  object-position: bottom;
}

.no-scale {
  transform: none !important;
}

.no-decoration {
  text-decoration: none !important;
}

.no-decoration:hover {
  text-decoration: none !important;
}

.user-menu {
  margin-left: -22%;
  color: #fff;
}

.class-type {
  height: 70vh;
  border-radius: 10px;
  transition: all 0.5s;
}

.select-image {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
  opacity: 0.5;
  border-radius: 10px;
}

.type-disabled {
  opacity: 0.5;
}

.class-card {
  background-color: #000;
  z-index: 1;
  border-radius: 10px;
}

.class-type:hover {
  transition: all 0.25s;
  transform: scale(1.01);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.class-card-wrapper:hover {
  transform: scale(1.01);
  transition: all 0.5s;
}

.select-title {
  color: #fff;
  z-index: 2;
}

.br-0 {
  border-radius: 0px !important;
}

.bottom-navbar {
  position: fixed;
  z-index: 10;
  bottom: 0px;
  width: 100%;
}

.bottom-navbar i {
  font-size: 24px;
}

.drawer-menu {
  z-index: 1;
  transition: all 0.25s;
}

.navbar-dark .navbar-nav .nav-link {
  color: #fff;
}

.list-style-none {
  list-style-type: none;
  padding-left: 0px;
}

.mobile {
  border-radius: 10px;
}

.show-tablet {
  display: none;
}

.landing-logo {
  width: 350px;
}

.landing-container {
  z-index: 1;
}

.landing-row {
  height: 500px;
  position: absolute;
  top: 20vh;
  width: 100%;
}

.landing-button {
  transition: all 0.25s;
}

.landing-button:hover {
  transform: scale(1.1);
}

.z-index-1 {
  z-index: 1;
}

.mw-500 {
  max-width: 500px;
}

.h-auto {
  height: auto;
}

.background-image {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.banner {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.package-description {
  max-height: 100px;
  overflow: hidden;
}

.package-card {
  height: 350px;
}

.strikethrough {
  text-decoration: line-through;
}

.landing-class-type {
  opacity: 1;
  display: block;
  width: 100%;
  height: 500px;
  max-height: 500px;
  object-fit: cover;
  transition: 0.5s ease;
  backface-visibility: hidden;
  z-index: 1;
}

.landing-class-type:after {
  background-color: #000;
  width: 100%;
  height: 100%;
}

.coach-home-img {
  height: 400px;
  object-fit: cover;
}

.black-overlay {
  background-color: #000;
  display: block;
  opacity: 0.4;
  height: 100%;
  width: 100%;
  z-index: 0;
  top: 0;
}

.map-container {
  margin-top: -3vh;
  height: 103vh;
  width: 100%;
  z-index: 1;
}

label,
span,
input {
  font-size: 16px;
}

.class-type-card {
  height: 200px;
  position: relative;
}

.video-card {
  height: 240px;
  position: relative;
}

.video-card img {
  height: 80%;
  width: 100%;
  border-radius: 10px;
  object-fit: cover;
}

.class-type-card img {
  height: 100%;
  width: 100%;
  border-radius: 10px;
  object-fit: cover;
}

.class-type-overlay {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  opacity: 0.25;
  z-index: 1;
  position: absolute;
  background: black;
  border-radius: 10px;
}

.class-type-caption {
  position: absolute;
  z-index: 2;
  width: 100%;
  bottom: 0px;
}

.class-type-caption p {
  font-size: 14px;
}

.class-type-description {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 75%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.wallet-card {
  width: 350px;
  height: 150px;
  position: relative;
  display: inline-block;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.wallet-card .metadata-container {
  position: absolute;
  bottom: 25px;
}

.wallet-metadata {
  font-size: 12px;
}

.wallet-container {
  overflow-x: scroll;
}

.wallet-wrapper {
  width: 180%;
}

.status * {
  font-size: 10px;
}

.landing-class-type-container {
  position: relative;
  overflow: hidden;
}
.landing-class-type-container:hover .landing-class-type {
  filter: brightness(20%);
}

.landing-class-type-container:hover .landing-class-type:after {
  opacity: 0.4;
}

.landing-class-type-container:hover .class-type-description {
  opacity: 1;
  color: #fff;
}
.landing-class-type-container:hover .class-type-logo {
  opacity: 0;
}

.class-type-logo {
  position: absolute;
  top: 200px;
  width: 50%;
  height: 50px;
  object-fit: contain;
  left: 25%;
  text-align: center;
}

.show-mobile {
  display: none;
}

.banner-inner {
  max-width: 1200px;
  margin: auto;
  overflow: hidden;
}

.class-package-thumbnail {
  max-width: 400px;
}

.bg-vertical {
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

div.app-landing {
  width: 100%;
  position: absolute;
  padding-bottom: 4rem !important;
  bottom: 0px;
}

.app-landing .container {
  max-width: 550px;
}

.user-card img {
  width: 35px;
  height: 35px;
  object-fit: cover;
  border-radius: 70px;
}

.charge-list {
  max-height: 80vh;
  overflow-y: scroll;
}

.comment-input {
  position: fixed;
  bottom: 0px;
  width: 100%;
  left: 0px;
}

.comment-feed {
  height: 70vh;
  overflow-y: scroll;
}

.time-left {
  padding-right: 0.65rem;
  padding-left: 0.65rem;
  margin-bottom: 0.5rem;
  width: 100%;
  height: 28px;
}

#scroll-text {
  /* animation properties */
  -moz-transform: translateX(100%);
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  -moz-animation: text-scroll 15s linear infinite;
  -webkit-animation: text-scroll 15s linear infinite;
  animation: text-scroll 15s linear infinite;
}

/* MENU-SIDEBAR */
#staticBackdrop .offcanvas-header > div > div {
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
}

#staticBackdrop .offcanvas-header h4 {
  color: gray;
  margin-bottom: 0.5rem;
  font-size: 12px;
  letter-spacing: 1px;
}

/* main-information */
#staticBackdrop .main-information > div:nth-child(2) {
  margin-bottom: 20px;
}

#staticBackdrop .main-information h2 {
  font-size: 20px;
  margin-bottom: 0.5px;
  color: #032251;
}

#staticBackdrop .main-information h4 {
  font-size: 12px;
}

#staticBackdrop .offcanvas-header button:nth-child(1) {
  margin-right: 14px;
}

#staticBackdrop .user-picture {
  border-radius: 50% !important;
  border: none !important;
  height: 50px !important;
  width: 50px !important;
}

.offcanvas-header > div > div:nth-child(1) button {
  background: none;
  border: none;
  height: auto !important;
  margin: 0 !important;
  padding: 0 !important;
}

#staticBackdrop .offcanvas-header button {
  border-radius: 5rem;
  font-size: 12px;
  padding: 8px;
}

/* SIDEBAR-BODY */
.offcanvas-body .col-4 > button > div {
  margin: 0 1rem;
}

.offcanvas-body .col-4 > button h4 {
  font-size: 12px;
  margin: 0;
}

.offcanvas-body .item-button {
  display: flex;
  justify-content: center;
}

.offcanvas-body .col-4 > button {
  background-color: white;
  border-radius: 0.4rem;
  border: 1px solid #f2f6fd !important;
  font-size: 12px;
  height: 139px;
  padding: 0.8rem 0.6rem !important;
  position: relative;
  width: 110px;
}

.offcanvas-body > div > div button > div {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-around;
}

.offcanvas-body button div {
  border: none !important;
}

.icon-wrapper i::before {
  color: #ced1db !important;
  font-size: 20px;
}

.icon-wrapper {
  background-color: #f2f6fd !important;
  border-radius: 0.8rem;
  height: 50px;
  width: 50px;
}

/* SIDEBAR - FOOTER */
.offcanvas-footer {
  display: flex;
  justify-content: space-between;
  padding: 16px !important;
  width: 100%;
}

.offcanvas-footer div {
  align-items: center;
  display: flex;
}

.offcanvas-footer h4 {
  font-size: 12px;
}

.offcanvas-footer .icon-wrapper {
  background-color: transparent !important;
  border: none !important;
  margin-bottom: 0 !important;
}

.offcanvas-footer .icon-wrapper i::before {
  color: var(--secondary) !important;
  font-size: 24px;
}

/* NOTIFICACIONES */
.notification::before {
  color: #c96654 !important;
  font-size: 22px;
  position: absolute;
  right: 20px;
  top: 10px;
}

.container.checkout {
  min-height: 82vh;
}

.fixed-bottom {
  z-index: 1 !important;
}

/* for Firefox */
@-moz-keyframes text-scroll {
  from {
    -moz-transform: translateX(100%);
  }

  to {
    -moz-transform: translateX(-100%);
  }
}

/* for Chrome */
@-webkit-keyframes text-scroll {
  from {
    -webkit-transform: translateX(100%);
  }

  to {
    -webkit-transform: translateX(-100%);
  }
}

@keyframes text-scroll {
  from {
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }

  to {
    -moz-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

@media (min-width: 1000px) {
  .hide-desktop {
    display: none;
  }
  .class-type-card {
    height: 300px;
  }
}

@media (max-width: 1000px) {
  .text-left-tablet {
    text-align: left !important;
  }
  .hide-tablet {
    display: none;
  }
  .show-tablet {
    display: inherit;
  }
}

@media (max-width: 767px) {
  .display-1 {
    font-size: 2.8rem !important;
  }
  h2.h3 {
    font-size: 20px !important;
  }
  .content {
    min-height: 115vh;
    max-width: 100vw;
  }
  .bottom-navbar {
    display: block;
  }
  .container-fluid.p-5 {
    padding: 12px !important;
  }
  .app-link-overlay {
    height: 25vh;
  }
  .home-img {
    height: 50%;
  }
  .schedule-mobile-container {
    overflow-x: scroll;
  }
  .schedule-mobile-wrapper {
    width: 350%;
  }
  .show-mobile {
    display: block;
  }
  .schedule-col {
    width: calc(100% / 7);
    margin-top: 32px;
  }
  .panel-mobile-menu {
    display: block;
  }
  .hide-mobile {
    display: none;
  }
  .side-menu {
    display: none;
  }
  .main-panel {
    padding-left: 0px;
  }
  .text-funny {
    font-size: 32px;
  }
  .nav-item {
    height: auto;
    padding-left: 8px;
    padding-right: 8px;
  }
  .nav-link {
    margin-top: 0px !important;
    text-transform: capitalize !important;
  }
  .mobile {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
  }
  .schedule-container {
    width: 100%;
  }
  .h-80 {
    height: auto;
  }
  .slideshow-logo {
    max-width: 250px;
  }
  .latest-card-content {
    width: 100%;
  }
  .latest-card-overlay {
    width: 100%;
  }
  .mobile-center {
    text-align: center;
  }
  .carousel-caption {
    bottom: 20% !important;
  }
  .carousel-caption .display-2 {
    font-size: 2rem;
  }
  #hero h1 {
    font-size: 5em;
  }
}
