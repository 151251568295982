:root {
    --dark: #010101;
        --gray: #333;
        --primary: #000099;
        --accent: #000099;
        --accent-light: #000099;
}
/*
ion-checkbox {
    --size: 24px;
    --background-checked: var(--primary) !important;
}

ion-checkbox::part(container) {
    border-radius: 6px;
    border: 2px solid var(--primary) !important;
}*/

:host {
    --background-checked: var(--primary, --primary);
    --border-color-checked: var(--primary, --primary);
}


ion-list {
    border: 1px solid rgba(0,0,0,0.1);
    border-radius: 10px;
}