#main {
    max-height: 100vh;
    padding-top: 60px;
    overflow-y: scroll;
    padding-left: 10px;
    padding-right: 10px;
}

.offcanvas {
    max-width: 90%;
}

.progress-dot {
    top: 55%;
    width: 12px;
    height: 12px;
    position: absolute;
    border-radius: 50px;
    background-color: var(--primary);
}

.icon-wrapper {
    border-radius: 100px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon-wrapper-lg {
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    border-radius: 200px;
    justify-content: center;
}

.due-date-card {
    border-left: 8px solid var(--primary) !important;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 0px;
}

.step-card, .activity-card, .process-card {
    width: 70vw;
    border: 0px;
    height: 139px;
    position: relative;
    display: inline-block;
}

.step-card .progress-container, .process-card .progress-container {
    position: absolute;
    bottom: 15px;
}

.step-card .metadata-container, .process-card .metadata-container {
    position: absolute;
    bottom: 25px;
}

.step-card h3, .activity-card h3, .process-card h3 {
    height: 2.8rem;
}

.step-metadata, .activity-metadata, .process-metadata {
    font-size: 12px;
}

.step-container, .activity-container, .process-container {
    max-height: 170px;
    overflow-x: scroll;
    overflow-y: hidden;
}


.step-wrapper, .activity-wrapper { 
    width: 180%;
}

.py-2 {
    padding-top: 0.35rem !important;
    padding-bottom: 0.35rem !important;
}


.ticket-left {
    border-left: 1px dotted rgba(0,0,0,0.1);
}

.ticket .top-border {
    width: 30px;
    height: 30px;
    background: var(--light);
    border-radius: 60px;
    position: absolute;
    left: -15px;
    top: -36px;
}

.ticket .time {
    font-size: 1em;
}

.ticket-card-wrapper {
    width: 500px;
    overflow-y: hidden;
    margin-right: 16px;
    display: inline-block;
}

.ticket-wrapper {
    max-width: 100%;
    overflow-x: scroll;
}

.ticket .bottom-border {
    width: 30px;
    height: 30px;
    background: var(--light);
    border-radius: 60px;
    position: absolute;
    left: -15px;
    bottom: -36px;
}

.merchants-map-card-wrapper {
    height: 200px;
}

.merchant-map-card {
    width: 400px;
    display: inline-block;
}

.merchant-map-card img {
    max-height: 150px;
    object-fit: contain;
}

.merchant-search-wrapper {
    max-width: 95vw;
    position: fixed;
    width: 100%;
    z-index: 10;
}

.merchant-card-wrapper {
    width: 500px;
    margin-right: 16px;
}

.merchant-card-wrapper .map-container {
    height: 100px;
}

.badge-pill {
    border-radius: 25px;
}

.ticket .badge * {
    font-weight: 400;
}

.ticket .badge-wrapper {
    min-width: 200px;
}

#merchants #navbar.navbar {
    background-color: transparent;
}

#greeting {
    background-image: url("../assets/images/parking.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin-top: -60px;
    height: 30vh;
}

#loading {
    background-image: url("../assets/images/Splash.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

@media(max-width: 1000px) {
    .merchant-map-card {
        width: 350px;
    }
}

@media(max-width: 767px) {
    .merchant-map-card {
        width: 90vw;
    }
    .ticket-card-wrapper {
        width: 350px;    
    }
    .ticket .top-border {
        top: -45px;
    }
    .ticket .bottom-border {
        bottom: -45px;
    }
    .merchant-card-wrapper {
        width: 350px;
        margin-right: 16px;
    }
}